import { NOT_FOUND } from "../constants/GlobalConstants";

interface NavigatorUAData {
  readonly brands: { readonly brands: string; readonly version: string }[];
  readonly mobile: boolean;
  readonly platform: string;
  getHighEntropyValues(hints: string[]): Promise<{ readonly model?: string }>;
}

export const getDeviceModel = async (navigator: Navigator): Promise<string> => {
  return new Promise((resolve) => {
    if ("userAgentData" in navigator) {
      (navigator.userAgentData as NavigatorUAData)
        .getHighEntropyValues(["model"])
        .then((value) => {
          resolve(value?.model || NOT_FOUND);
        })
        .catch(() => {
          resolve(NOT_FOUND);
        });
    } else {
      const userAgentExtract = navigator.userAgent.match(
        /\((?:[^;]+;){2}\s*([^;)]+)/
      );
      const deviceModelFromUA = userAgentExtract
        ? userAgentExtract[1]
        : NOT_FOUND;
      resolve(deviceModelFromUA);
    }
  });
};
