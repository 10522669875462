import { useEffect, useState } from "react";
import {
    getAppleAppUrl,
    getCookie, getCountryFromLocale,
    getDeviceArchitecture,
    updateAppUrls
} from "../utils/constants/PagesConstants";
import { INITIAL, NOT_FOUND } from "../utils/constants/GlobalConstants";
import { IAppVersionData, IDeviceArchitechture } from "../types/common";
import useIsIOS from "../utils/helpers/useIsIOS";
import { getDeviceModel } from "../utils/helpers/user-agent";

interface IUseApkUrl {
    locale: string,
    appVersions: IAppVersionData,
    pageName: string,
    deviceArchitectureMapping: IDeviceArchitechture
}

export const useApkUrl = (props: IUseApkUrl) => {
    const { locale, appVersions, pageName, deviceArchitectureMapping } = props;

    const iosUrl: string = getAppleAppUrl(getCountryFromLocale(locale))

    const [apkUrl, setApkUrl] = useState(updateAppUrls(INITIAL, locale, appVersions, pageName))
    const [deviceModel, setDeviceModel] = useState<string | undefined>(undefined)
    const isIosDevice = useIsIOS()

    useEffect(() => {
        const fetchDeviceModel = async () => {
            if (!deviceModel) {
                const model = await getDeviceModel(navigator)
                setDeviceModel(model)
            }
        };
        fetchDeviceModel();
    }, [])

    useEffect(() => {
        if (deviceModel) {
            let deviceArchitecture = getDeviceArchitecture(deviceArchitectureMapping ,deviceModel || NOT_FOUND);
            setApkUrl(updateAppUrls(deviceArchitecture, locale, appVersions, pageName));
        }
    }, [deviceModel]);

    const getApkUrlFromDeviceType = () => {
        // if (isDownloadDisabled) {
        //     return "#"
        // }

        if (isIosDevice) return iosUrl;
        return apkUrl;
    }

    return {androidUrl: apkUrl, downloadUrl: getApkUrlFromDeviceType()}
}